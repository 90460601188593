import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
  selector: 'app-forget-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.css'
})
export class ForgetPasswordComponent implements OnInit {

  forgorPasswordForm!: FormGroup

  constructor(private fb: FormBuilder){}

  ngOnInit(): void {
    this.forgorPasswordForm = this.fb.group({
      email: ['', Validators.required]
    })
  }


}
