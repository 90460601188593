import { Component, ElementRef, HostBinding, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '../services/user.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { LoaderComponent } from "../common/loader/loader.component";
import { VendorDashboardComponent } from "../vendor-dashboard/vendor-dashboard.component";
import { environment } from '../../environments/environment';

declare var Razorpay: any;

@Component({
    selector: 'app-profile',
    standalone: true,
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.css',
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, LoaderComponent, VendorDashboardComponent]
})
export class ProfileComponent implements OnInit{

  username = localStorage.getItem('name');
  useremail = localStorage.getItem('email');
  userdata = localStorage.getItem('user');
  changePasswordForm!: FormGroup
  profileForm!: FormGroup

  user_subscription_plan_id: any;
  selectedPlan: any;
  loading: boolean = false
  email: any
  user:any
  subscriptionPlans: any
  versions = ["1.0", "2.0", "3.0"]
  usedV1Questions: any = 0
  usedV2Questions: any = 0
  usedV3Questions: any = 0
  selectedMonths: any = 1
  months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  selectedVersionForAddOn: any
  noOfQuestions: any = 1
  currentDate = new Date()

  @ViewChild('myModal') myModal: ElementRef | undefined;
  @ViewChild('monthsModal') monthsModal!: ElementRef;
  @ViewChild('closeMonths') closeMonths!: ElementRef;
  @ViewChild('addOnQuestion') addOnQuestion!: ElementRef;

  planResponse: any;
  sub_id: any;
  amount: any;
  selectedPlan_id: any;
  selectedPlanToUpgrade: any;
  currentSubscription: any;
  isPlanActive: boolean = true;

  constructor(
    private router: Router,
    private dataService: UserService,
    private fb: FormBuilder,
    private toastr: ToastrService
  ){}


  ngOnInit(): void {

    this.user = this.userdata!=null ? JSON.parse(this.userdata) : null
    this.changePasswordForm = this.fb.group({
      // oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirm_password: ['', Validators.required]
    }, {
      validator: this.confirmedValidator('newPassword', 'confirm_password')
    })

    this.profileForm = this.fb.group({
      name:  [this.username, Validators.required],
      phone:  ["", Validators.required],
      address:  ["", Validators.required],
      city:  ["", Validators.required],
      state:  ["", Validators.required],
      zip:  ["", Validators.required]
    })

    this.getSubscriptions()
    this.getQuestionCount()
    this.getSubscription()
    
  }

  // Confirm Password
  confirmedValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
        return;
      }
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ confirmedValidator: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  openModal() {
    this.myModal!.nativeElement.style.display = "block";

  }

  closeModal() {
    this.myModal!.nativeElement.style.display = 'none';
  }

  getSubscription() {
    this.subscriptionPlans = []
    // this.dataService.getPlans()
    //   .subscribe((planResponse: any) => {
    //     console.log('razorpay',planResponse)
    //    // this.subscriptionPlans = planResponse
    //   })
    this.dataService.getSubscriptions()
      .subscribe((planResponse: any) => {
        this.subscriptionPlans = planResponse.results
      })
  }

  addQestion(version: any){
    let subscriptionDate = new Date(this.selectedPlan['subscription_expiry_date'])
    if(this.currentDate > subscriptionDate){
      this.toastr.warning(`You can not add more questions. Your ${this.planResponse[0]['plan_name']} plan is expired please upgrade your plan.`)
      return
    }
    this.selectedVersionForAddOn = version
    this.addOnQuestion.nativeElement.click()
  }

  addMoreQuestion(){
    this.toastr.warning("We are working on this, please check after some time")
  }

  selectMonths(plan: any){
    this.selectedPlanToUpgrade = plan
    this.monthsModal.nativeElement.style.display = "block"
  }

  subscribPlan(planData: any = null){
    this.loading = true
    planData = this.selectedPlanToUpgrade
    let subscriptionData = {
      //plan_id: planData.id, // razorpay plan id
      plan_id: (planData && planData.razorpay_subscription_plan_id) ?? this.sub_id,
      total_count: 12,
      quantity: parseInt(this.selectedMonths)
    }
    
    let plan_amount = (planData && planData.amount) ?  (planData.amount * this.selectedMonths) * 100 : (this.amount * this.selectedMonths) * 100;
    this.dataService.createSubscription(subscriptionData)
    .subscribe((subscribResponse: any) => {
      this.loading = false
      console.log('subscription response',subscribResponse);
      return new Promise((resolve, reject) => {
        const options = {
          key: environment.razorpay_key_id,
          amount: plan_amount,
          currency: 'INR', // Change to your currency
          name: 'TaxBotGPT',
          description: 'TaxBotGPT Subscription',
          image: 'assets/images/taxbotgpt-60.png', // URL of your company logo
          // subscription_id: subscribResponse.id, // Order ID generated from server
          handler: (response: any) => {
            if(planData == null){
              this.saveSubscriptionRenewDetail(response, subscribResponse.id)
            }else{
              this.savePaymentDetail(response, subscribResponse.id)
            }
            resolve(response);
          },
         /* prefill: {
            email: 'tset92k@gmail.com',
            contact: '9604042331',
          },*/
          theme: {
            color: '#F37254'
          }
        };
        const razorpay = new Razorpay(options);
        razorpay.open();
      });
    })
  }

  saveSubscriptionRenewDetail(paymentResponse: any, subscription_id: any){
    if(paymentResponse && paymentResponse.razorpay_payment_id){
      this.dataService.getPaymentDetail(paymentResponse.razorpay_payment_id)
      .subscribe((paymentDetail: any) => {
        if(paymentDetail && (paymentDetail.status == "authorized" || paymentDetail.status == "captured")){
          let subscriptionObj = {
            user_id: localStorage.getItem("id"),
            plan_id: this.selectedPlan_id,
            payment_id: paymentResponse.razorpay_payment_id
          }
          this.dataService.saveSubscriptionRenewDetail(subscriptionObj)
          .subscribe((response: any) => {
            if(response && response.status == "success"){
              this.toastr.success("Subscribed Successfully")
              this.closeModal()
              // window.location.reload()
              // this.router.navigateByUrl('dashboard')
              // window.location.href = '/dashboard'
            }
          })
        }
      })
    }
  }

  savePaymentDetail(paymentResponse: any, subscription_id: any){
    if(paymentResponse && paymentResponse.razorpay_payment_id){
      this.dataService.getPaymentDetail(paymentResponse.razorpay_payment_id)
      .subscribe((paymentDetail: any) => {
        if(paymentDetail && (paymentDetail.status == "authorized" || paymentDetail.status == "captured")){
          let subscriptionObj = {
            user_id: localStorage.getItem("id"),
            plan_id: this.selectedPlan_id,
            payment_id: paymentResponse.razorpay_payment_id,
            months: this.selectedMonths
          }
          this.dataService.saveSubscriptionDetail(subscriptionObj)
          .subscribe((response: any) => {
            if(response && response.status == "success"){
              this.closeModal()
              this.toastr.success("Subscribed Successfully")
              this.monthsModal.nativeElement.style.display = "none"
              // this.router.navigateByUrl('dashboard')
              // window.location.href = '/dashboard'
            }
          })
        }
      })
    }
  }


  getSubscriptions(){
    this.dataService.questionCount({
      user_id: localStorage.getItem("id"),
      version: localStorage.getItem("selectedVersion") ?? '1.0'
    }).subscribe(res => {
      if(res && res.user_subscription_plan_id){
        this.user_subscription_plan_id = res.user_subscription_plan_id;
        this.selectedPlan = res
        this.currentSubscription = res

        let subscriptionDate = new Date(this.selectedPlan['subscription_expiry_date'])
        if(this.currentDate > subscriptionDate){
          this.isPlanActive = false
        }
      }
    });
  }

  getQuestionCount(){
    this.versions.map((version: any) => {
      this.dataService.questionCount({
        user_id: localStorage.getItem("id"),
        version: version
      }).subscribe(res => {
        //console.log(res);
        if(res && res.user_subscription_plan_id){
          this.getplan(res.billing_cycle, res.user_subscription_plan_id)
          if(version == "1.0"){
            this.usedV1Questions = res.question_count
          }else if(version == "2.0"){
            this.usedV2Questions = res.question_count
          }else if(version == "3.0"){
            this.usedV3Questions = res.question_count
          }
        }
      });
    })
  }

  getplan(plan_cycle:any, plan_id: any){
    this.dataService.getSubscriptions(plan_cycle)
    .subscribe((planResponse: any) => {
      if(planResponse.results){
        
        this.planResponse = planResponse.results.filter((resp: any) => resp.id == plan_id)
        console.log("---- :: ", this.planResponse)
        this.sub_id = this.planResponse[0].razorpay_subscription_plan_id
        this.amount = this.planResponse[0].amount
        this.selectedPlan_id = this.planResponse[0].id
      }
    }, (error) => {
    })
  }

  dashboard(){
    this.router.navigateByUrl('dashboard')
  }

  newChat() {
    localStorage.setItem('login_now', "1")
    this.router.navigateByUrl('/dashboard')
    //location.reload()
  }

  updateProfile(){
    this.loading = true
    let obj = {
      user_id: localStorage.getItem("id"),
      name: this.profileForm.controls['name'].value,
      phone: this.profileForm.controls['phone'].value,
      address: this.profileForm.controls['address'].value,
      city: this.profileForm.controls['city'].value,
      state: this.profileForm.controls['state'].value,
      zip: this.profileForm.controls['zip'].value
    }
    this.dataService.updateAddress(obj)
    .subscribe((userResponse: any) => {
      if(userResponse && userResponse.status == "success"){
        this.toastr.success(userResponse.message)
        this.loading = false
      }else{
        this.toastr.error("Oops, Something went wrong")
        this.loading = false
      }
    }, (error) => {
      this.toastr.error("Oops, Something went wrong")
      this.loading = false
    })
  }

  updatePassword(){
    this.loading = true
    let obj = {
      user_id: localStorage.getItem("id"),
      password: this.changePasswordForm.controls['newPassword'].value
    }

    this.dataService.updatePassword(obj)
    .subscribe((resetPassResponse: any) => {
      if(resetPassResponse && resetPassResponse.status == "success"){
        this.toastr.success(resetPassResponse.message)
        this.changePasswordForm.reset()
        this.loading = false
      }else{
        this.toastr.error("Oops, Something went wrong")
        this.changePasswordForm.reset()
        this.loading = false
      }
    }, (error) => {
      this.changePasswordForm.reset()
      this.toastr.error("Oops, Something went wrong")
      this.loading = false
    })
  }


  closeMonthsModal(){
    this.monthsModal.nativeElement.style.display = "none"
  }

  sendInvite(){
    console.log("--- : ", this.email)
  }

}
