import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { UserService } from '../services/user.service';
import { GoogleLoginProvider, GoogleSigninButtonDirective, GoogleSigninButtonModule, SocialAuthService } from '@abacritt/angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { BrowserModule } from '@angular/platform-browser';


@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, GoogleSigninButtonModule, RouterModule],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.css'
})
export class SignInComponent implements OnInit {
  userPassword: any;
  userName: any;
  loginForm: any;
  isSubmitted = false;

  width: number = 100


  constructor(
    private router: Router, 
    private fb: FormBuilder, 
    private userService: UserService, 
    private socialAuthService: SocialAuthService,
    private toastr: ToastrService) {
    this.createForm();
  }

  ngOnInit(): void {
    this.socialAuthService.authState.subscribe((user) => {
      console.log(user)
      //perform further logics
      localStorage.setItem("logoutType", "social");

      this.userService.saveUser(user.name, user.email, '123456','','https://taxbotgpt.ai/', user.provider).subscribe(
        response => {
          console.log('User saved successfully!', response);

          localStorage.setItem("name", response.user.name);
          localStorage.setItem("email", response.user.email);
          localStorage.setItem("id", response.user.id);
          localStorage.setItem("login_now", "1");
          
          this.router.navigateByUrl('/dashboard')
        },
        error => {
          console.error('Error saving user:', error);
          this.userService.login(user.email, '123456').subscribe(
            response => {
              console.log('User saved successfully!', response);
              this.loginForm.reset(); // Reset the form after successful submission
              //alert("You Have signup successfully. Please login now")
              localStorage.setItem("name", response.user.name);
              localStorage.setItem("email", response.user.email);
              localStorage.setItem("id", response.user.id);
              localStorage.setItem("login_now", "1");

              //console.log(response.success.name);
              this.router.navigateByUrl('/dashboard')
            },
            error => {
              alert("Invalid User and Password")
              console.error('Error login user:', error);
            }
          );
        }
      );
    });
  }

  createForm() {
    this.loginForm = this.fb.group({

      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],

    });
  }


  doGoogleLogin() {
    this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
      .then(() => {

        //this.router.navigate(['mainpage'];
      });


  }

  doLogin() {
    this.isSubmitted = true;
    console.log(this.loginForm)
    if (this.loginForm.valid) { // Check if the form is valid

      const formData = this.loginForm.value;
      this.userService.login(formData.email, formData.password).subscribe(
        response => {
          console.log('User saved successfully!', response);
          this.loginForm.reset(); // Reset the form after successful submission
          //alert("You Have signup successfully. Please login now")
          localStorage.setItem("name", response.user.name);
          localStorage.setItem("email", response.user.email);
          localStorage.setItem("id", response.user.id);
          localStorage.setItem("login_now", "1");

          localStorage.setItem("user", JSON.stringify(response))

          this.toastr.success(` Welcome ${response.user.name}`)
          //console.log(response.success.name);
          // if(response.user.role == 4){
          //   this.router.navigateByUrl('/vendor-dashborad')
          // }else{
            this.router.navigateByUrl('/dashboard')
         // }
        },
        error => {
          this.toastr.error("Invalid User and Password")
          // alert("Invalid User and Password")
          console.error('Error login user:', error);
        }
      );
    } else {
      //alert("Please enter user and password");
    }

  }


}
