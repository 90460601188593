import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { authGuard } from './auth.guard';
import { SharedComponent } from './shared/shared.component';
import { ProfileComponent } from './profile/profile.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { VendorRegisterComponent } from './src/vendor-register/vendor-register.component';
import { VendorDashboardComponent } from './vendor-dashboard/vendor-dashboard.component';
import { EnterpriseComponent } from './enterprise/enterprise.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';

export const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'sign-in', component: SignInComponent },
    { path: 'sign-up', component: SignUpComponent },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'forget-password', component: ForgetPasswordComponent },
    { path: 'change-password', component: ChangePasswordComponent}, 
    { path: 'profile', component:ProfileComponent, canActivate: [authGuard]},
    //{ path: 'about-us', loadComponent: () => import('./about-us/about-us.component').then(m => m.AboutUsComponent) },
    { path: 'about-us', component: AboutUsComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'dashboard', component: DashboardComponent, canActivate: [authGuard] },
    { path: 'c/:sessionId', component: DashboardComponent, canActivate: [authGuard] },
    { path: 'share/:sessionId', component: SharedComponent },
    { path: 'term-condition', component: TermConditionComponent },
    { path: 'vendor/onboarding', component: VendorRegisterComponent },
    // { path: 'vendor-dashborad', component: VendorDashboardComponent},
    { path: 'enterprise-enquiry', component: EnterpriseComponent}
];
