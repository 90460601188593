<div class="login-page">

    <!-- ==== / mouse cursor drag end ==== -->
    <div id="smooth-wrapper">
        <div id="smooth-content">
            <!-- ==== main start ==== -->
            <main class="authentication">
                <section class="authentication-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="overflow-hidden radius col-md-8 col-lg-11 m-auto text-center shadow-lg"
                                style="border-radius: 5px;">
                                <div class="row">
                                    <div class="col-lg-6 p-0 loginbg">
                                        <div class="login-leftside">
                                            <h2 class="heading">
                                                Reset your password
                                            </h2>
                                            <p class="sub-title">
                                               Enter a new password to reset the password on your account.
                                            </p>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 p-0">

                                        <div class="login-section authentication__content p-5">
                                            <img src="assets/images/logo.png" class="img-fluid">
                                            <div class="authentication__inner">
                                                <form [formGroup]="forgorPasswordForm">
                                                    <div class="input-single">
                                                        <div class="ic-group">
                                                            <input type="password" formControlName="password"
                                                                placeholder="New Password" required>
                                                            <span class="material-symbols-outlined">
                                                                key
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(!forgorPasswordForm?.get('password')?.valid && forgorPasswordForm?.get('password')?.touched )"
                                                            class="text-danger mt-1" style="text-align: left;">
                                                            Please enter new password
                                                        </div>
                                                    </div>
                                                    <div class="input-single">
                                                        <div class="ic-group">
                                                            <input type="password" formControlName="confirm_password"
                                                                placeholder="Confirm Password" required>
                                                            <span class="material-symbols-outlined">
                                                                key
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(!forgorPasswordForm?.get('confirm_password')?.valid && forgorPasswordForm?.get('confirm_password')?.touched )"
                                                            class="text-danger mt-1" style="text-align: left;">
                                                            Please enter confirm password
                                                        </div>
                                                    </div>
                                                    <div class="section__content-cta">
                                                        <button type="submit" class="btn btn--primary">
                                                            Reset password
                                                        </button>
                                                    </div>
                                                </form>
                                                <div class="divider">
                                                    <span></span>
                                                    <p>Or continue with</p>
                                                    <span></span>
                                                </div>
                                            </div>
                                            <div class="auth-footer">
                                                <p>
                                                    Don't have an account?
                                                    <a href="sign-up">Sign Up!</a>
                                                </p>
                                                <div class="section__content-cta">
                                                    <a href="/" class="btn btn--primary">Back To Home</a>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <!-- ==== / main end ==== -->
        </div>
    </div>
</div>