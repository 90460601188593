import { Component, ElementRef, ViewChild, viewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [CommonModule, HttpClientModule, FormsModule, ReactiveFormsModule],
  templateUrl: './sign-up.component.html',
  styleUrl: './sign-up.component.css'
})
export class SignUpComponent {
  @ViewChild('myModal') myModal: ElementRef | undefined;

  name: string | undefined;
  email: string | undefined;
  password: string | undefined;
  cpassword: string | undefined;
  userForm: any;
  acceptTerms: any
  isSubmitted = false;

  constructor(
      private userService: UserService, 
      private fb: FormBuilder, 
      private route: Router,
      private toastr: ToastrService) {

    this.createForm();
  }




  createForm() {
    this.userForm = this.fb.group({
      name: ['', Validators.required], // Set up validation rules
      phone: ['', Validators.required], // Set up validation rules
      email: ['', [Validators.required, Validators.email]],
      referral: [''],      
      password: ['', Validators.required],
      cpassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue]
    }

    );
  }


  onSubmit() {
    this.isSubmitted = true;
    console.log(this.userForm)
    if (this.userForm.valid) { // Check if the form is valid

      const formData = this.userForm.value;
      this.userService.saveUser(formData.name, formData.email, formData.password, formData.referral, 'https://taxbotgpt.ai/').subscribe(
        response => {
          console.log('User saved successfully!', response);
          this.userForm.reset(); // Reset the form after successful submission
          this.toastr.success('You Have signup successfully. Please login now')
          this.route.navigateByUrl('/sign-in')
        },
        error => {
          console.error('Error saving user:', error);
          this.toastr.error('Email id exist or server error')
        }
      );
    } else {
      // alert("Please enter valid email or required data")
    }
  }


  openModal() {
    this.myModal!.nativeElement.style.display = "block";

  }
  closeModal() {
    this.myModal!.nativeElement.style.display = 'none';
  }
}



