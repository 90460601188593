import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-vendor-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './vendor-dashboard.component.html',
  styleUrl: './vendor-dashboard.component.css'
})
export class VendorDashboardComponent {

  email: any
  constructor(private router: Router){}

  logout(){
    localStorage.clear()
    this.router.navigateByUrl('/')
  }
  
  sendInvite(){
    console.log("--- : ", this.email)
  }

}
