<!--Modal HTML -->
<div class="m-4">
    <div #myModal class="modal" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" style="color:#17202A;">Terms and Conditions</h5>
                    <button type="button" class="btn-close" (click)="closeModal()" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <p class="descriptions" style="color:#17202A; font-size:14px; line-height:23px;">Certainly! It's
                        important to note that while I strive to provide accurate and helpful information,
                        my responses are generated based on patterns and information present in the data on which I was
                        trained.
                        I do not have access to real-time data, and my responses may not always reflect the most current
                        developments or changes in laws and regulations.</p>
                    <p class="descriptions" style="color:#17202A; font-size:14px; line-height:23px;">Additionally, the
                        information I provide should not be considered as legal, financial, or professional advice.
                        It is always recommended to verify any information with authoritative sources and consult with
                        relevant professionals for specific guidance.</p>
                    <p class="descriptions" style="color:#17202A; font-size:14px; line-height:23px;">If you have legal
                        or critical concerns, it is advisable to seek the assistance of qualified professionals who can
                        provide advice tailored to your specific situation.
                        My responses are intended for general informational purposes only</p>
                </div>
                <div class="modal-footer">
                    <button type="button" (click)="closeModal()" class="btn btn-warning" data-bs-dismiss="modal"
                        style="color:black;">Accept</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!--End Modal HTML -->

<div class="login-page">
<div class="my-app service-app">
    <div id="smooth-wrapper">
        <div id="smooth-content">
            <main class="authentication auth-create" data-background="assets/images/auth-line.png">
                <section class="authentication-wrapper">
                    <div class="container">
                        <div class="row">
                            <div class="overflow-hidden radius col-md-8 col-lg-11 m-auto text-center shadow-lg" style="border-radius: 5px;">
                                <div class="row">
                                    <div class="col-lg-6 p-0 loginbg">
                                        <div class="login-leftside">
                                            <h1> Your <br> Personalised <br> AI Tax <br> Assistant </h1>
                                        </div>
                                    </div>

                                    <div class="col-lg-6 p-0">
                                        <div class="login-section authentication__content p-5">
                                            <img src="assets/images/logo.png" class="img-fluid">
                                            <div class="authentication__inner">
                                                <form [formGroup]="userForm" (ngSubmit)="onSubmit()">
                                                    <div class="input-single">
                                                        <!-- <label for="createuserName">Your Name</label> -->
                                                        <div class="ic-group">
                                                            <input type="text" formControlName="name" name="createuser-name"
                                                                placeholder="Full Name" required>
                                                            <span class="material-symbols-outlined">
                                                                person
                                                            </span>

                                                        </div>
                                                        <div *ngIf="(userForm?.get('name')?.invalid && userForm?.get('name')?.touched) || (userForm?.get('name')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Please enter a name
                                                        </div>
                                                    </div>

                                                    <div class="input-single">
                                                        <!-- <label for="createuserName">Mobile No.</label> -->
                                                        <div class="ic-group">
                                                            <input type="text" formControlName="phone" name="createuser-name"
                                                                placeholder="Mobile No." required>
                                                            <span class="material-symbols-outlined">
                                                                phone
                                                            </span>

                                                        </div>
                                                        <div *ngIf="(userForm?.get('phone')?.invalid && userForm?.get('phone')?.touched) || (userForm?.get('phone')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Please enter a mobile no.
                                                        </div>
                                                    </div>
                                                    <div class="input-single">
                                                        <!-- <label for="create-useremail">Your Email</label> -->
                                                        <div class="ic-group">
                                                            <input type="text" formControlName="email" name="create-user-email"
                                                                placeholder="Enter Mail" required>
                                                            <span class="material-symbols-outlined">
                                                                mail
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(userForm?.get('email')?.invalid && userForm?.get('email')?.touched) || (userForm?.get('email')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Please enter email
                                                        </div>
                                                    </div>
                                                    <div class="input-single">
                                                        <!-- <label for="createPassword">Password</label> -->
                                                        <div class="ic-group pass">
                                                            <span class="material-symbols-outlined show-pass">
                                                                visibility_off
                                                            </span>
                                                            <input type="password" formControlName="password"
                                                                name="create-Password" placeholder="Enter Password" required>
                                                            <span class="material-symbols-outlined">
                                                                key
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(userForm?.get('password')?.invalid && userForm?.get('password')?.touched) || (userForm?.get('password')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Please enter Password
                                                        </div>
                                                    </div>
                                                    <div class="input-single">
                                                        <!-- <label for="createconfirmPassword">Confirm Password</label> -->
                                                        <div class="ic-group pass">
                                                            <span class="material-symbols-outlined show-pass">
                                                                visibility_off
                                                            </span>
                                                            <input type="password" formControlName="cpassword"
                                                                name="createconfirm-Password" placeholder="Confirm Password"
                                                                required>
                                                            <span class="material-symbols-outlined">
                                                                key
                                                            </span>
                                                        </div>
                                                        <div *ngIf="(userForm?.get('cpassword')?.invalid && userForm?.get('cpassword')?.touched) || (userForm?.get('cpassword')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Please enter confirm Password
                                                        </div>
                                                    </div>

                                                    <div class="input-single">
                                                        <div class="ic-group pass">
                                                            <input type="text" formControlName="referral"
                                                                name="referral" placeholder="Referral Code">
                                                            <span class="material-symbols-outlined">
                                                                <svg xmlns="http://www.w3.org/2000/svg" 
                                                                height="24px" 
                                                                viewBox="0 -960 960 960" width="24px" fill="#f9c613"><path d="M680-280q25 0 42.5-17.5T740-340q0-25-17.5-42.5T680-400q-25 0-42.5 17.5T620-340q0 25 17.5 42.5T680-280Zm0 120q31 0 57-14.5t42-38.5q-22-13-47-20t-52-7q-27 0-52 7t-47 20q16 24 42 38.5t57 14.5ZM480-80q-139-35-229.5-159.5T160-516v-244l320-120 320 120v227q-19-8-39-14.5t-41-9.5v-147l-240-90-240 90v188q0 47 12.5 94t35 89.5Q310-290 342-254t71 60q11 32 29 61t41 52q-1 0-1.5.5t-1.5.5Zm200 0q-83 0-141.5-58.5T480-280q0-83 58.5-141.5T680-480q83 0 141.5 58.5T880-280q0 83-58.5 141.5T680-80ZM480-494Z"/></svg>
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <div class="" style="color: #fff;">
                                                        <input type="checkbox" formControlName="acceptTerms" />
                                                        I have read and agree to the <a (click)="openModal()">Terms &amp;
                                                            Condition</a>
                                                        <div *ngIf="(userForm?.get('acceptTerms')?.invalid && userForm?.get('acceptTerms')?.touched) || (userForm?.get('acceptTerms')?.invalid && isSubmitted)"
                                                            class="text-danger mt-3">
                                                            Accept term is required.
                                                        </div>
                                                    </div>
                                                    <div class="section__content-cta">
                                                        <button type="submit" class="btn btn--primary">Create Account</button>
                                                    </div>
                                                    <!-- <div class="divider">
                                                        <span></span>
                                                        <p>Or continue with</p>
                                                        <span></span>
                                                    </div> -->
                                                </form>
                                                <div class="auth-footer">
                                                    <p> Have an account? <a href="sign-in">Sign In!</a> </p>
                                                    <div class="section__content-cta">
                                                        <a href="/" class="btn btn--primary">Back To Home</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    </div>
</div>
</div>