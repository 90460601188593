<div class="container-fluid">
    <h3 class="mb-3" style="color:#fff;">Vendor Dashboard</h3>
    <div class="row mb-6 g-6 card-block">
        <div class="col-sm-6 col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="content-left">
                            <h5 class="mb-1">₹0</h5>
                            <small>Total Earning</small>
                        </div>
                        <span class="badge bg-label-primary rounded-circle p-2">
                            <i class="fa fa-credit-card-alt ri-24px"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="content-left">
                            <h5 class="mb-1">₹0</h5>
                            <small>Unpaid Earning</small>
                        </div>
                        <span class="badge bg-label-success rounded-circle p-2">
                            <i class="fa fa-gift ri-24px"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-xl-4">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="content-left">
                            <h5 class="mb-1">0</h5>
                            <small>Signups</small>
                        </div>
                        <span class="badge bg-label-danger rounded-circle p-2">
                            <i class="fa fa-users ri-24px"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <div class="row mb-6 g-6">
        <div class="col-lg-7 how-to-use">
            <div class="card h-100">
                <div class="card-body">
                    <h5 class="mb-1">How to use</h5>
                    <p class="mb-6 card-subtitle mt-0">Integrate your referral code in 3 easy steps.</p>
                    <div class="d-flex flex-column flex-sm-row justify-content-between text-center gap-6">
                        <div class="d-flex flex-column align-items-center">
                            <span class="p-4 border-1 border-primary rounded-circle border-dashed mb-0 w-px-75 h-px-75">
                                <img src="assets/images/vendor/rocket.svg" alt="Rocket"></span>
                            <h5>1. Send Invitation</h5>
                            <p class="my-2 w-75">Create &amp; validate your referral link and get</p>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <span class="p-4 border-1 border-primary rounded-circle border-dashed mb-0 w-px-75 h-px-75">
                                <img src="assets/images/vendor/user-info.svg" alt="Rocket"></span>
                            <h5>2. Registration</h5>
                            <p class="my-2 w-75">For every new signup you get</p>
                        </div>
                        <div class="d-flex flex-column align-items-center">
                            <span class="p-4 border-1 border-primary rounded-circle border-dashed mb-0 w-px-75 h-px-75">
                                <img src="assets/images/vendor/paper.svg" alt="Rocket"></span>
                            <h5>3. Get Commissions</h5>
                            <p class="my-2 w-75">Get other friends to generate link and get</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="card h-100">
                <div class="card-body">
                    <form class="referral-form" onsubmit="return false">
                        <div class="mb-6 mt-1">
                            <h5 class="mb-1">Invite your friends</h5>
                            <p class="mb-5">Invite your friends and earn lifelong recurring commissions from every
                                purchase they make</p>
                            <div class="d-flex g-2">
                                <div class="w-100">
                                    <div class="me-3">
                                        <input 
                                            [(ngModel)]="email"
                                            type="mail" 
                                            id="referralEmail" 
                                            name="referralEmail"
                                            class="form-control form-control-sm"
                                            placeholder="Enter friend's email &amp; invite">
                                    </div>
                                </div>
                                <div>
                                    <button 
                                        (click)="sendInvite()"
                                        type="button" 
                                        class="btn btn-primary waves-effect waves-light">
                                        <i class="ri-check-line ri-16px me-1_5"></i>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="pt-4">
                <h5 class="mb-5">Share the referral link</h5>
                <div class="d-flex g-2">
                  <div class="w-100">
                    <div class="me-3">
                      <input type="text" id="referralLink" name="referralLink" class="form-control form-control-sm" placeholder="pixinvent.com/?ref=6479" fdprocessedid="kd4t5b">
                    </div>
                  </div>
                  <div>
                    <div class="d-flex gap-2">
                      <button type="button" class="btn btn-facebook btn-icon waves-effect waves-light">
                        <i class="ri-facebook-circle-line text-white ri-22px"></i>
                    </button>
                      <button type="button" class="btn btn-twitter btn-icon waves-effect waves-light">
                        <i class="ri-twitter-line text-white ri-22px"></i>
                    </button>
                    </div>
                  </div>
                </div>
              </div> -->
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-datatable table-responsive">
            <div id="DataTables_Table_0_wrapper" class="dataTables_wrapper dt-bootstrap5 no-footer">
                <div class="card-header d-flex flex-column flex-md-row pt-md-0 pb-0 align-items-center">
                    <div class="head-label">
                        <h5 class="card-title text-nowrap mb-0">Referred users</h5>
                    </div>
                </div>
                <table class="datatables-referral table dataTable no-footer dtr-column" id="DataTables_Table_0"
                    aria-describedby="DataTables_Table_0_info" style="width: 958px;">
                    <thead>
                        <tr>
                            <th class="control sorting_disabled dtr-hidden" rowspan="1" colspan="1"
                                style="width: 0px; display: none;" aria-label=""></th>
                            <th class="sorting_disabled dt-checkboxes-cell dt-checkboxes-select-all" rowspan="1"
                                colspan="1" style="width: 18px;" data-col="1" aria-label=""><input type="checkbox"
                                    class="form-check-input"></th>
                            <th class="sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                colspan="1" style="width: 314px;" aria-label="Users: activate to sort column descending"
                                aria-sort="ascending">Users</th>
                            <th class="text-nowrap sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                colspan="1" style="width: 106px;"
                                aria-label="Referred ID: activate to sort column ascending">Referred ID</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                style="width: 93px;" aria-label="Status: activate to sort column ascending">Status</th>
                            <th class="sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1" colspan="1"
                                style="width: 81px;" aria-label="Value: activate to sort column ascending">Value</th>
                            <th class="text-nowrap sorting" tabindex="0" aria-controls="DataTables_Table_0" rowspan="1"
                                colspan="1" style="width: 86px;"
                                aria-label="Earnings: activate to sort column ascending">Earnings</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <tr class="odd">
                            <td class="  control" tabindex="0" style="display: none;"></td>
                            <td class="  dt-checkboxes-cell"><input type="checkbox"
                                    class="dt-checkboxes form-check-input"></td>
                            <td class="sorting_1">
                                <div class="d-flex justify-content-start align-items-center customer-name">
                                    <div class="avatar-wrapper me-4">
                                        <div class="avatar avatar-sm"><img src="assets/images/vendor/18.png"
                                                alt="Avatar" class="rounded-circle"></div>
                                    </div>
                                    <div class="d-flex flex-column"><a
                                            href="app-ecommerce-customer-details-overview.html"
                                            class="text-heading"><span class="fw-medium text-truncate">Allsun
                                                Wrotchford</span></a><small
                                            class="text-nowrap">awrotchford25fc2.com</small></div>
                                </div>
                            </td>
                            <td><span class="text-heading">1094</span></td>
                            <td><span class="badge rounded-pill bg-label-danger" text-capitalized="">Rejected</span>
                            </td>
                            <td><span class="text-heading">₹6186.51</span></td>
                            <td><span class="text-heading">₹653.95</span> </td>
                        </tr> -->
                    
                    </tbody>
                </table>
                <div style="width: 1%;"></div>
            </div>
        </div>

    </div>
</div>