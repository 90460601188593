<app-loader *ngIf="loading"></app-loader>
<div class="userpage h-100">
  <div class="container-fluid p-0 m-0">
    <div class="row">
      <div class="col-md-3">

        <div class="profile-sidebar-portlet h-100 p-3">

          <a 
            (click)="newChat()"
            class="new-chat chat p-2 rounded-pill d-flex justify-content-between" 
            style="text-decoration: none;">
            <div class="logo"><img src="assets/images/taxbotgpt-60.png">
              <span class="mx-2">New chat</span>
            </div>
            <div class="d-flex align-items-center">
              <i class="fa-regular fa-pen-to-square" style="color: #ffffff;"></i>
            </div>
          </a>

          <!-- <div class="profile-userpic">
                    <img src="assets/images/user.png" class="img-responsive" alt=""> </div> -->

          <div class="profile-usertitle pt-5">
            <div class="profile-usertitle-name"> {{ username }} </div>
            <div class="profile-usertitle-job"> {{ useremail }} </div>
          </div>
          <div class="profile-usermenu">

            <ul class="nav" id="v-pills-tab" role="tablist" aria-orientation="vertical">

              <li *ngIf="user && user.user.role==4">
                <a href="#" id="vendor-dashboard-tab" data-bs-toggle="pill" data-bs-target="#vendor-dashboard"
                  role="tab" aria-controls="vendor-dashboard" aria-selected="false">
                  <i class="icon-info"></i> Dashboard </a>
              </li>
              <li>
                <a href="#" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" role="tab"
                  aria-controls="v-pills-profile" aria-selected="true"  class="active">
                  <i class="icon-settings"></i> My Subscription </a>
              </li>
              <li>
                <a href="#" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" role="tab"
                  aria-controls="v-pills-home" aria-selected="false">
                  <i class="icon-home"></i> Profile
                </a>
              </li>
              <!-- <li>
                <a href="#" id="profile_invoice-tab" data-bs-toggle="pill" data-bs-target="#profile_invoice"
                  role="tab" aria-controls="profile_invoice" aria-selected="false">
                  <i class="icon-info"></i> Invoices </a>
              </li> -->
              <li>
                <a href="#" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings"
                  type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">
                  <i class="icon-info"></i> Change Password </a>
              </li>
              <!-- <li>
                <a (click)="dashboard()">
                  <i class="icon-info"></i> Dashboard </a>
              </li> -->
            </ul>


          </div>
        </div>

      </div>

      <div class="col-md-9">
        <button class="themechange-btn" (click)="dashboard()"> <i class="bi-x-circle"></i> </button>
        <div class="portlet p-5 tab-content profile-rightsidebar col-lg-12 m-auto mt-4" id="v-pills-tabContent">

          <div class="profile-wrapper tab-pane fade" id="v-pills-home" role="tabpanel"
            aria-labelledby="v-pills-home-tab" tabindex="0">

            <div class="col-lg-12 m-auto">
              <div class="card">
                <div class="card-header bg-success">
                  <h2 class="user-title text-white"> Profile </h2>
                </div>
                <div class="card-body">
                  <div class="profile" id="profile-section">
                    <form [formGroup]="profileForm" (ngSubmit)="updateProfile()">

                      <div class="row">

                        <div class="mb-3 col-lg-6">
                          <label class="form-label"> Name </label>
                          <input 
                            type="text" 
                            class="form-control shadow-sm" 
                            placeholder="Name"
                            formControlName="name">
                            <div class="text-danger form-error ps-3"
                              *ngIf="!profileForm.get('name')?.valid && profileForm.get('name')?.touched">
                              Please enter name
                          </div>
                        </div>
                        <div class="mb-3 col-lg-6">
                          <label for="email" class="form-label"> Email </label>
                          <input 
                            [disabled]="true"
                            type="email"
                            class="form-control" 
                            id="email" 
                            placeholder="Email"
                            [value]="useremail">
                        </div>

                        <div class="mb-3 col-lg-6">
                          <label class="form-label"> Phone </label>
                          <input 
                            placeholder="Phone"
                            type="text" 
                            class="form-control shadow-sm" 
                            formControlName="phone">
                            <div class="text-danger form-error ps-3"
                              *ngIf="!profileForm.get('phone')?.valid && profileForm.get('phone')?.touched">
                              Please enter phone
                          </div>
                        </div>

                        <div class="mb-3 col-lg-6">
                          <label class="form-label"> Address </label>
                          <input 
                            type="text" 
                            class="form-control shadow-sm" 
                            formControlName="address"
                            placeholder="Address">
                            <div class="text-danger form-error ps-3"
                              *ngIf="!profileForm.get('address')?.valid && profileForm.get('address')?.touched">
                              Please enter address
                          </div>
                        </div>

                        <div class="mb-3 col-lg-6">
                          <label class="form-label"> City </label>
                          <input 
                            type="text" 
                            class="form-control shadow-sm" 
                            formControlName="city"
                            placeholder="City">
                            <div class="text-danger form-error ps-3"
                              *ngIf="!profileForm.get('city')?.valid && profileForm.get('city')?.touched">
                              Please enter city
                          </div>
                        </div>

                        <div class="mb-3 col-lg-6">
                          <label class="form-label"> State </label>
                          <input 
                            type="text" 
                            class="form-control shadow-sm" 
                            formControlName="state"
                            placeholder="State">
                            <div class="text-danger form-error ps-3"
                              *ngIf="!profileForm.get('state')?.valid && profileForm.get('state')?.touched">
                              Please enter state
                          </div>
                        </div>

                        <div class="mb-3 col-lg-6">
                          <label class="form-label"> Zip </label>
                          <input 
                            type="text" 
                            class="form-control shadow-sm" 
                            formControlName="zip"
                            placeholder="Zip">
                            <div class="text-danger form-error ps-3"
                              *ngIf="!profileForm.get('zip')?.valid && profileForm.get('zip')?.touched">
                              Please enter zip
                          </div>
                        </div>

                    </div>

                      <button 
                        [disabled]="!profileForm.valid"
                        type="submit" 
                        class="btn mt-4"
                        [ngClass]="!profileForm.valid ? 'btn-disable' : 'btn-primary' ">Submit</button>
                    </form>
                  </div>
                </div>
              </div>
              <!-- <h2 class="user-title mb-4"> Profile </h2> -->
              <!-- <div class="profile" id="profile-section">
                <form>
                  <div class="mb-3">
                    <label for="usename" class="form-label"> Name </label>
                    <input type="text" class="form-control shadow-sm" id="username" [value]="username">
                  </div>
                  <div class="mb-3">
                    <label for="email" class="form-label"> Email </label>
                    <input type="email" class="form-control shadow-sm" id="email" [value]="useremail">
                  </div>
                  <button type="submit" class="btn btn-primary mt-4">Submit</button>
                </form>
              </div> -->
            </div>


          </div>

          <div class="profile change-password tab-pane fade" id="v-pills-settings" role="tabpanel"
            aria-labelledby="v-pills-settings-tab" tabindex="0">
            <div class="col-lg-9">
              <div class="card">
                <div class="card-header bg-success">
                  <h2 class="user-title text-white"> Change Password </h2>
                </div>
                <div class="card-body">
                  <form [formGroup]="changePasswordForm" (ngSubmit)="updatePassword()">
                    <!-- <div class="mb-3">
                      <label for="usename" class="form-label"> Current Password</label>
                      <input 
                        type="password" 
                        class="form-control shadow-sm"
                        formControlName="oldPassword">
                        <div class="text-danger form-error ps-3"
                            *ngIf="!changePasswordForm.get('oldPassword')?.valid && changePasswordForm.get('oldPassword')?.touched">
                            Please enter old password
                        </div>
                    </div> -->
                    <div class="mb-3">
                      <label class="form-label"> New Password </label>
                      <input 
                        type="password" 
                        class="form-control shadow-sm" 
                        placeholder="Enter new password"
                        formControlName="newPassword">
                        <div class="text-danger form-error ps-3"
                            *ngIf="!changePasswordForm.get('newPassword')?.valid && changePasswordForm.get('newPassword')?.touched">
                            Please enter new password
                        </div>
                    </div>
                    <div class="mb-3">
                      <label class="form-label"> Confirm New Password
                      </label>
                      <input 
                        type="password" 
                        class="form-control shadow-sm"
                        placeholder="Enter confirm password" 
                        formControlName="confirm_password">
                        <div class="text-danger form-error ps-3"
                            *ngIf="!changePasswordForm.get('confirm_password')?.valid && changePasswordForm.get('confirm_password')?.touched  && changePasswordForm.get('confirm_password')?.errors">
                            Please confirm password
                        </div>
                    </div>
                    <button 
                      [disabled]="!changePasswordForm.valid"
                      type="submit" 
                      class="btn mt-4"
                      [ngClass]="!changePasswordForm.valid ? 'btn-disable' : 'btn-primary' ">Update Password</button>
                  </form>
                </div>
              </div>
              <!-- <h2 class="user-title mb-4"> Change Password </h2>
              <form>
                <div class="mb-3">
                  <label for="usename" class="form-label"> Current Password <small> ( +8 characters ) </small></label>
                  <input type="password" class="form-control shadow-sm" id="username">
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label"> New Password <small> ( +8 characters ) </small> </label>
                  <input type="password" class="form-control shadow-sm" id="npassword">
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label"> Confirm New Password <small> ( +8 characters ) </small>
                  </label>
                  <input type="password" class="form-control shadow-sm" id="npassword">
                </div>
                <button type="submit" class="btn btn-primary mt-4">Update Password</button>
              </form> -->
            </div>
          </div>


          <div class="my-subscription tab-pane show active" id="v-pills-profile" role="tabpanel"
            aria-labelledby="v-pills-profile-tab" tabindex="0">

            <div class="card">
              <div class="card-header bg-success">
                <h2 class="user-title text-white"> My Subscription </h2>
              </div>
              <div class="card-body">
                <form *ngIf="selectedPlan; else npPlanSubscribed">
                  <div class="mb-3 position-relative p-4 susbcription-tab">
                    <span class="activebtn" *ngIf="isPlanActive"> Active </span>
                    <span class="expiredbtn" *ngIf="!isPlanActive"> Expired </span>
                    <h5> {{ selectedPlan['plan_name'] }} ({{ selectedPlan['billing_cycle']}}) <span> {{selectedPlan['amount']}}</span></h5>
                    <h6 class="mt-2"> Subscription End Date : <span class="text-dark">{{ selectedPlan['subscription_expiry_date'] | date: 'longDate' }}</span> </h6>
                    <table class="table table-striped mt-2">
                      <thead>
                        <tr class="text-center">
                          <th>Model Versions</th>
                          <th>Questions in Your Plan</th>
                          <th>Left Questions in Your Plan</th>
                          <th>Add on Question</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td class="text-center">V1.0</td>
                          <td class="text-center">
                            {{ planResponse[0]['total_question_v1'] }}
                          </td>
                          <td class="text-center">
                            {{ planResponse[0]['total_question_v1'] - usedV1Questions > 0 ? (planResponse[0]['total_question_v1'] - usedV1Questions) : 0 }}
                          </td>
                          <td class="text-center">
                            <button 
                              class="btn btn-primary btn-upgrade"
                              (click)="addQestion(1)"> 
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add more questions 
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">V2.0</td>
                          <td class="text-center">
                            {{ planResponse[0]['total_question_v2'] }}
                          </td>
                          <td class="text-center">
                            {{ planResponse[0]['total_question_v2'] - usedV2Questions  > 0 ? (planResponse[0]['total_question_v2'] - usedV2Questions) : 0 }}
                          </td>
                          <td class="text-center">
                            <button 
                              class="btn btn-primary btn-upgrade"
                              (click)="addQestion(2)"> 
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add more questions 
                            </button>
                          </td>
                        </tr>
                        <tr>
                          <td class="text-center">V3.0</td>
                          <td class="text-center">
                            {{ planResponse[0]['total_question_v3'] }}
                            
                          </td>
                          <td class="text-center">
                            {{ planResponse[0]['total_question_v3'] - usedV3Questions > 0 ? (planResponse[0]['total_question_v3'] - usedV3Questions) : 0 }}
                          </td>
                          <td class="text-center">
                            <button 
                              class="btn btn-primary btn-upgrade"
                              (click)="addQestion(3)"> 
                              <i class="fa fa-plus-circle" aria-hidden="true"></i> Add more questions 
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <p>This subscription will be automatically renewed every monthly.</p>
                    <!-- <ul>
                      <li class="ps-3" *ngFor="let feature of selectedPlan[0]['features']"> 
                        <i class="fa fa-check-circle"></i> {{ feature }}
                     </li>
                    </ul> -->
                  </div>
                  <button
                    (click)="openModal()"
                    type="button" 
                    class="btn btn-primary mt-4"> Upgrade 
                  </button>
                </form>
                <ng-template #npPlanSubscribed>
                  <div class="mb-3 position-relative p-4 susbcription-tab">
                    <h5> CHOOSE YOUR BEST PLAN </h5>
                    <p> AI Tax generator tools have emerged as powerful resources for 
                      unleashing creative possibilities and transforming. </p>
                  </div>
                  <a 
                    href="/#plans"
                    target="_blank"
                    type="button" 
                    class="btn btn-primary mt-4"> Select Plan </a>
                </ng-template>
              </div>
            </div>

            <!-- <h2 class="user-title mb-4"> My Subscription </h2>
            <form>
              <div class="mb-3 position-relative p-4 susbcription-tab">
                <span class="activebtn"> Active </span>
                <h5> Standard (monthly) </h5>
                <p> This subscription will be automatically renewed every monthly. </p>
              </div>
              <button type="submit" class="btn btn-primary mt-4"> Upgrade </button>
            </form> -->
          </div>
          <div class="invoices tab-pane fade" id="vendor-dashboard" role="tabpanel"
            aria-labelledby="vendor-dashboard-tab" tabindex="0" *ngIf="user && user.user.role==4">
            <app-vendor-dashboard></app-vendor-dashboard>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #myModal class="modal" tabindex="-1">
    <div class="modal-dialog modal-lg shadow-lg">
        <div class="modal-content">
            <div class="modal-header bg-success">
                <h5 class="modal-title text-white" id="exampleModalLabel" style="text-align: center;">Upgrade your plan
                </h5>
                <button type="button" class="close" (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                    <span class="text-white" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-lg-3 upgradepopup">
              <section id="pricing" class="pricing-content section-padding">
                <div class="container-fluid">					
                  <div class="section-title text-center">
                    <h2>Choose Your Best Plan</h2>
                    <p>
                      AI Tax generator tools have emerged as powerful resources for unleashing creative possibilities and transforming.
                    </p>
                  </div>				
                  <div class="row">									
                    <div 
                      *ngFor="let plan of subscriptionPlans"
                      class="col-lg-3 col-sm-6 col-xs-12 wow fadeInUp" 
                      data-wow-duration="1s" 
                      data-wow-delay="0.1s" 
                      data-wow-offset="0" 
                      style="visibility: visible; animation-duration: 1s; animation-delay: 0.1s; animation-name: fadeInUp;padding: 0px;">
                      <div class="pricing_design">
                        <div class="single-pricing">
                          <div class="price-head">		
                            <h2 style="color: #6c757d;">{{ plan.plan_name }}</h2>
                            <h4 
                              style="font-weight: 800;"
                              class="text-center" 
                              *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                              ₹{{plan.price_per_month}} /month
                            </h4>
                          </div>
                          <div class="text-center" *ngIf="plan.plan_type!='free' && plan.plan_type!='enterprise'">
                            <!-- (click)="subscribPlan(plan)"  -->
                            <button 
                              (click)="selectMonths(plan)"
                              class="price_btn">
                              Upgrade to {{ plan.plan_name }}
                            </button>
                          </div>
                          <div class="text-center mt-5" *ngIf="plan.plan_type=='enterprise'">
                            <a href="/enterprise-enquiry"> <button class="price_btn"> Contact for Sales </button></a>
                         </div>
                          <ul>
                            <li  *ngFor="let feature of plan.features">
                              <i class="fa fa-check-circle f-18 mr-2"></i> {{feature}}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>	  
                  </div>
                </div>
              </section>
            </div>
            <div class="modal-footer d-flex justify-content-center">
                <a href="https://taxbotgpt.ai/contact-us" class="price_btn" role="button">Need more capabilities?
                    <span class="text-white"> Contact Taxbotai. </span></a>
            </div>

        </div>
    </div>
</div>

<div #monthsModal class="modal" id="monthsModal" data-bs-backdrop='static'>
  <div class="modal-dialog vertical-align-center modal-dialog-scrollable">
    <div class="modal-content modal-lg">
      <div class="modal-header bg-success">
          <h5 class="modal-title modal-md">Select Months</h5>
          <button 
            (click)="closeMonthsModal()"
            type="button" 
            class="btn-close" 
            data-bs-dismiss="modal" 
            aria-label="Close"></button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
          <label>Select how many months to be upgrade</label>
          <select 
              id="notice_type" 
              [(ngModel)]="selectedMonths" 
              class="form-control">
              <option 
                *ngFor="let month of months" 
                [value]="month">{{ month }}
              </option>
          </select>
      </div>        
      <!-- Modal footer -->
      <div class="modal-footer">
          <button
              (click)="subscribPlan()"
              type="button"
              class="btn btn-primary">
              Submit
          </button>
      </div>
    </div>
  </div>
</div>

<button 
    style="display: none;"
    #addOnQuestion
    type="button" 
    class="btn btn-primary" 
    data-bs-toggle="modal" 
    data-bs-target="#addOnQuestion">
    Open modal
</button>

<div class="modal" id="addOnQuestion" data-bs-backdrop='static'>
    <div class="modal-dialog modal-md modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header bg-success">
            <h5 class="modal-title modal-md">Add Question</h5>
            <button type="button" #closeRenewButton class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
            <h5>How much question you want add? </h5>
            <input 
              type="number" 
              [(ngModel)]="noOfQuestions" 
              class="form-control" 
              placeholder="Please enter number of questions"
              min="1" 
              [value]="noOfQuestions">
        </div>        
        <!-- Modal footer -->
        <div class="modal-footer">
            <button
                (click)="addMoreQuestion()"
                type="button"
                class="btn btn-default share-btn"
                >
                Renew
            </button>
        </div>
      </div>
    </div>
  </div>