import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.css'
})
export class ChangePasswordComponent {
  forgorPasswordForm!: FormGroup

  constructor(private fb: FormBuilder){}

  ngOnInit(): void {
    this.forgorPasswordForm = this.fb.group({
      password: ['', Validators.required],
      confirm_password: ['', Validators.required]
    })
  }
}
